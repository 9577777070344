import { useEffect, useState } from "react";
import { ContentCard, Section } from "components/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOneContent } from "./queries";

const ContentDetails = () => {
  const { contentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contentInfo, setContentInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneContent(contentId);
        setContentInfo(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 800);
      } catch (error) {
        toast.error("Something went wrong, try again!");
      }
    })();
  }, [contentId]);

  return (
    <Section>
      <ContentCard
        contentData={contentInfo}
        goBack={() => navigate("/content")}
        editContent={() => navigate(`/content/edit/${contentId}`)}
        isLoading={isLoading}
      />
    </Section>
  );
};

export default ContentDetails;
