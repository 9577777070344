import { useEffect, useRef, useState } from "react";
import { getOneCity } from "views/CityDetails/queries";
import { Button, Input, Title } from "components/index";
import { IoCloudUploadSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { textOnlyValidator } from "helpers/validation";
import { editCity, putImage } from "./queries";
import placeholderImg from "../../assets/images/placeholder-city-image.png";
import "./edit-city.scss";
const EditCity = () => {
  const navigate = useNavigate();
  const { cityId } = useParams();
  const fileInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState({ cityId, image: "" });
  const [cityData, setCityData] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const [cityPic, setCityPic] = useState({});
  const [errorObj, setErrorObj] = useState({
    cityError: "",
    countryError: "",
    latError: "",
    longError: "",
    aliasesError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneCity(cityId);

        setCityData({
          name: res.data?.name,
          status: res.data?.status.toString(),
          country: res.data?.country,
          longitude: Number(res.data?.longitude),
          latitude: Number(res.data?.latitude),
          aliases: res.data?.aliases,
        });
        setCityPic({
          cityBaseUrl: res.data?.cityBaseUrl,
          image: res.data?.image,
        });
        setDisabledInputs({ trips: res.data?.trips, users: res.data?.users });
      } catch (error) {
        toast.error("Something went wrong, try again.");
      }
    })();
  }, [cityId]);

  const handleInputChange = (e, type) => {
    if (type === "name") {
      const res = e.target.value.replace(textOnlyValidator, "");
      return setCityData({ ...cityData, [type]: res });
    }

    if (type === "country") {
      const res = e.target.value.replace(textOnlyValidator, "");
      return setCityData({ ...cityData, [type]: res });
    }

    setCityData({ ...cityData, [type]: e.target.value });
  };

  const handleFileChange = (e) => {
    const fileUploaded = e.target.files[0];

    if (fileUploaded) {
      const filereader = new FileReader();
      filereader.readAsDataURL(fileUploaded);
      filereader.onload = (e) => {
        const base64 = e.target.result;
        setImageUpload({
          ...imageUpload,
          image: base64,
        });
      };
    }
  };

  const handleEditCity = async (e, cityId, cityData) => {
    e.preventDefault();

    if (
      !cityData.country.length &&
      !cityData.name.length &&
      !cityData.latitude.length &&
      !cityData.longitude.length &&
      !cityData.aliases.length
    ) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "Please fill in this field",
        longError: "Please fill in this field",
        latError: "Please fill in this field",
        aliasesError: "Please fill in this field",
      });
      return;
    }

    if (!cityData.country.length && !cityData.name.length) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "Please fill in this field",
        longError: "",
        latError: "",
        aliasesError: "",
      });
      return;
    }

    if (!cityData.name.length) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "",
        longError: "",
        latError: "",
        aliasesError: "",
      });
      return;
    }
    if (!cityData.aliases.length) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "",
        longError: "",
        latError: "",
        aliasesError: "Please fill in this field",
      });
      return;
    }
    if (!cityData.country.length) {
      setErrorObj({
        cityError: "",
        countryError: "Please fill in this field",
        longError: "",
        latError: "",
        aliasesError: "",
      });
      return;
    }

    if (!cityData.latitude && !cityData.longitude) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Please fill in this field",
        latError: "Please fill in this field",
        aliasesError: "",
      });
      return;
    }

    if (!cityData.latitude) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "",
        latError: "Please fill in this field",
        aliasesError: "",
      });
      return;
    }

    if (!cityData.longitude) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Please fill in this field",
        latError: "",
        aliasesError: "",
      });
      return;
    }

    if (cityData.longitude > 180 && cityData.latitude > 90) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Longitude must be up to 180",
        latError: "Latitude must be up to 90",
        aliasesError: "",
      });
      return;
    }

    if (cityData.longitude > 180) {
      setErrorObj({
        latError: "",
        cityError: "",
        countryError: "",
        longError: "Longitude must be up to 180",
        aliasesError: "",
      });
      return;
    }

    if (cityData.latitude > 90) {
      setErrorObj({
        longError: "",
        cityError: "",
        countryError: "",
        latError: "Latitude must be up to 90",
        aliasesError: "",
      });
      return;
    }

    setIsLoading(true);
    try {
      if (!imageUpload.image.length) {
        setCityData({ ...cityData, image: cityPic.image });
      } else {
        await putImage(imageUpload);
      }

      const res = await editCity(cityId, cityData);
      toast.success(`${res.data?.name} updated successfully!`);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/cities");
      }, 500);
    } catch (error) {
      setIsLoading(false);
      toast.warning(`${error.response.data.message}`);
    }
  };

  return (
    <section className="edit__container">
      <div className="edit__wrapper">
        <div className="edit_header__wrapper">
          <Title title="Edit City" />

          <div className="icon_img__wrapper">
            <IoCloudUploadSharp
              className="upload_icon"
              onClick={() => fileInput.current.click()}
            />

            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/svg"
              onChange={handleFileChange}
              ref={fileInput}
            />

            {imageUpload?.image ? (
              <img
                className="edit__image"
                src={imageUpload.image}
                alt={`${cityData?.name}-picture`}
                onClick={() => fileInput.current.click()}
              />
            ) : (
              <img
                className="edit__image"
                src={
                  cityPic?.image
                    ? `${cityPic?.cityBaseUrl}/${cityPic?.image}`
                    : placeholderImg
                }
                alt={`${cityData?.name}-picture`}
                onClick={() => fileInput.current.click()}
              />
            )}
          </div>
        </div>

        <hr className="divider" />

        <form
          className="edit__form"
          onSubmit={(e) => handleEditCity(e, cityId, cityData)}
        >
          <Input
            inputType="text"
            inputLabel="City"
            inputValue={cityData?.name}
            handleChange={(e) => handleInputChange(e, "name")}
            error={errorObj.cityError}
          />

          <Input
            inputType="text"
            inputLabel="Country"
            inputValue={cityData?.country}
            handleChange={(e) => handleInputChange(e, "country")}
            error={errorObj.countryError}
          />

          <Input
            inputType="number"
            inputStep="any"
            inputLabel="Latitude"
            placeholderText={"Maximum 90"}
            inputValue={cityData?.latitude}
            handleChange={(e) => handleInputChange(e, "latitude")}
            error={errorObj.latError}
          />

          <Input
            inputType="number"
            inputStep="any"
            inputLabel="Longitude"
            placeholderText={"Maximum 180"}
            inputValue={cityData?.longitude}
            handleChange={(e) => handleInputChange(e, "longitude")}
            error={errorObj.longError}
          />

          <Input
            inputType="number"
            inputLabel="Number of Users"
            inputValue={disabledInputs?.users}
            isDisabled={true}
          />

          <Input
            inputType="number"
            inputLabel="Number of Trips"
            inputValue={disabledInputs?.trips}
            isDisabled={true}
          />
          <Input
            tooltipText="Enter cities aliases separated by commas"
            tooltipID="my-tooltip"
            inputType="textarea"
            inputStep="any"
            inputLabel="Aliases"
            inputValue={cityData?.aliases}
            handleChange={(e) => handleInputChange(e, "aliases")}
            error={errorObj.longError}
          />

          <ReactTooltip id="aliasesTip" place="top" effect="solid">
            Tooltip for the register button
          </ReactTooltip>
          <ReactTooltip id="my-tooltip" />
          <div className="edit_btn__wrapper">
            <Button
              content={"Cancel"}
              btnStyle="secondary"
              action={() => navigate("/cities")}
            />
            <Button content={"Save"} btnStyle={"save"} isDisabled={isLoading} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditCity;
