import { useEffect, useState } from "react";
import { Loader } from "components/index";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { getTerms } from "../queries";
import "../static-pages.scss";

const TermsAndConditions = () => {
  const [terms, setTerms] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await getTerms();
        if (res.data?.description === null) {
          setTerms(
            `<h1>${res.data?.title}</h1><br/><p>There is no information to show.</p>`
          );
        } else {
          setTerms(res.data?.description);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error("There was an error, please try again.");
      }
    })();
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  if (isLoading) return <Loader />;

  return (
    <section className="section__body terms">
      <article
        className="static__container"
        dangerouslySetInnerHTML={createMarkup(terms)}
      ></article>
    </section>
  );
};

export default TermsAndConditions;
