import { useEffect } from "react";
import { Button, Section } from "components/index";
import { useNavigate } from "react-router-dom";
import "./not-found.scss";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Section>
      <div className="page_not_found__wrapper">
        <h1>Oops...this page is not available!</h1>
        <Button
          btnStyle={"primary"}
          content="Return to homepage"
          action={() => {
            navigate("/");
          }}
        />
      </div>
    </Section>
  );
};

export default NotFound;
