import { useEffect, useState } from "react";
import { CityCard, Section } from "components/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOneCity } from "./queries";

const CityDetails = () => {
  const { cityId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [cityInfo, setCityInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneCity(cityId);
        setCityInfo(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 800);
      } catch (error) {
        toast.error("Something went wrong, try again!");
      }
    })();
  }, [cityId]);

  return (
    <Section>
      <CityCard
        cityData={cityInfo}
        goBack={() => navigate("/cities")}
        editCity={() => navigate(`/cities/edit/${cityId}`)}
        isLoading={isLoading}
      />
    </Section>
  );
};

export default CityDetails;
