/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { ContentState, convertFromHTML } from "draft-js";
import Button from "../Button";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./text-editor.scss";

const TextEditor = ({ contentData, goBack, handleSubmit, isLoading }) => {
  const [data, setData] = useState(contentData.description);

  const blocksFromHTML = convertFromHTML(data);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [contentState, setContentState] = useState(
    EditorState.createWithContent(state)
  );

  return (
    <>
      <Editor
        editorState={contentState}
        onEditorStateChange={setContentState}
        wrapperClassName="static__container wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: ["inline", "blockType", "list", "emoji", "history"],
          inline: {
            options: ["bold", "italic", "underline", "monospace"],
          },
        }}
      />
      <div className="edit_content_btn__wrapper">
        <Button content={"Cancel"} btnStyle="secondary" action={goBack} />

        <Button
          content={"Save"}
          btnStyle="save"
          isDisabled={isLoading}
          action={(e) => {
            const newContentData = {
              type: contentData.type,
              title: contentData.title,
              description: convertToHTML(contentState.getCurrentContent()),
            };
            handleSubmit(e, newContentData);
          }}
        />
      </div>
    </>
  );
};

export default TextEditor;
