import { ToastContainer } from "react-toastify";
import { PaginationProviderWrapper } from "helpers/pagination.context";
import { Webapp } from "./components";
import { UserProviderWrapper } from "./helpers/user.context";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <UserProviderWrapper>
      <PaginationProviderWrapper>
        <ToastContainer theme="colored" />
        <Webapp />
      </PaginationProviderWrapper>
    </UserProviderWrapper>
  );
};
export default App;
