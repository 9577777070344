/* eslint-disable react/prop-types */
import "./button.scss";

const Button = ({ content, btnStyle, isDisabled, action }) => {
  return (
    <button
      onClick={action}
      disabled={isDisabled}
      className={`btn__${btnStyle}`}
    >
      {content}
    </button>
  );
};

export default Button;
