import axios from "axios";

export const loginUser = async (loginData) => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
    ...loginData,
    isAdmin: true,
  });

  return res.data;
};
