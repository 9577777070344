/* eslint-disable indent */
/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";
import Button from "components/general/Button";
import Select from "components/general/Select";
import TableFilter from "components/general/TableFilter";
import Title from "components/general/Title";
import { FaEdit, FaEye, FaRegTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import { toast } from "react-toastify";
import PopUp from "../PopUp";
import "./table.scss";

const Table = ({
  selectOptions,
  setPerPage,
  perPage,
  totalCount,
  searchInTable,
  searchPlaceholder,
  dataList,
  headersList,
  tableTitle,
  actions,
  deleteContent,
  pageCount,
  currentPage,
  setCurrentPage,
}) => {
  const [data, setData] = useState([]);
  const columns = useMemo(() => [...headersList], [headersList]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentToDelete, setContentToDelete] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  if (perPage) {
    useEffect(() => {
      setPageSize(perPage);
    }, [perPage]);
  }

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await deleteContent(id);
      const filteredData = data.filter((item) => item.id !== contentToDelete);
      setData(filteredData);
      toast.success("Delete successful!");
      setTimeout(() => {
        setIsLoading(false);
        setModalIsOpen(false);
      }, 500);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      setModalIsOpen(false);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
  } = useTable({ columns, data }, usePagination);

  // const options = [
  //   ...selectOptions.filter((val) => val.value < totalCount),
  //   { value: totalCount, label: "All" },
  // ];

  return (
    <>
      <div className="title__container">
        <Title title={tableTitle} />
        <div className="filter_btn__wrapper">
          {actions.addUrl && (
            <Button
              btnStyle={"add"}
              content="+"
              action={() => navigate(actions.addUrl)}
            />
          )}
          {selectOptions && (
            <Select
              inputValue={perPage}
              className={"pagination__input"}
              options={selectOptions}
              onChange={(e) => {
                setTimeout(() => {
                  setPerPage(e.target.value);
                  e.target.value > totalCount
                    ? setCurrentPage(1)
                    : setCurrentPage(currentPage);
                }, 500);
              }}
            />
          )}
          {searchInTable && (
            <TableFilter
              searchFunction={searchInTable}
              searchPlaceholder={searchPlaceholder}
            />
          )}
        </div>
      </div>

      <table {...getTableProps()} className="table__container">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps()}
                  className="table__headers"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps()}
                      className="table__cells"
                    >
                      {cell.column.id === "actions" ? (
                        <div className="table_actions__wrapper">
                          <Link
                            className="action__link"
                            to={`${actions.viewUrl}${cell?.row?.original?.id}`}
                          >
                            <FaEye />
                          </Link>

                          <Link
                            className="action__link"
                            to={`${actions.editUrl}${cell?.row?.original?.id}`}
                          >
                            <FaEdit />
                          </Link>

                          {deleteContent ? (
                            <>
                              <div className="action__link">
                                <FaRegTrashAlt
                                  onClick={() => {
                                    setModalIsOpen(true);
                                    setContentToDelete(cell.row.original.id);
                                  }}
                                />
                              </div>

                              <PopUp
                                setOpenPopUp={setModalIsOpen}
                                openPopUp={modalIsOpen}
                                isLoading={isLoading}
                                deleteAction={async () => {
                                  await handleDelete(contentToDelete);
                                }}
                              />
                            </>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="button__wrapper">
        {pageCount > 1 && (
          <>
            <Button
              content="Previous"
              btnStyle="primary"
              isDisabled={currentPage <= 1}
              action={() => setCurrentPage(currentPage - 1)}
            />
            <div>
              <p>
                <input
                  className="pagination__input"
                  type={"number"}
                  value={
                    currentPage < 1
                      ? ""
                      : currentPage > pageCount
                      ? setCurrentPage(pageCount)
                      : currentPage
                  }
                  max={pageCount}
                  min={1}
                  onChange={(e) => {
                    setCurrentPage(Number(e.target.value));
                  }}
                />{" "}
                of <strong>{pageCount}</strong>
              </p>
            </div>
            <Button
              content="Next"
              btnStyle="primary"
              isDisabled={currentPage >= pageCount}
              action={() => setCurrentPage(currentPage + 1)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Table;
