import axios from "axios";

export const getContent = async (page, perPage) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/content?page=${page}&limit=${perPage}`,
    {
      headers: {
        "content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("x-auth-token")}`,
      },
    }
  );

  return res.data;
};
