import { useRef, useState } from "react";
import { Button, Input, Title } from "components/index";
import AuthCode from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailValidator, passwordValidator } from "helpers/validation";
import { forgotPassword, putNewPassword } from "./queries";
import { putOtpCode } from "./queries";
import "./forgot-password.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState("Step 1");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [otpCode, setOtpCode] = useState(null);
  const AuthInputRef = useRef(null);
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  //   STEP 1
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (emailValidator.test(email) === false) {
        setMessage("Please enter a valid email address.");
        return;
      }

      const res = await forgotPassword({ email, isAdmin: true });
      setMessage(`${res?.message}, please check your email.`);
      setSteps("Step 2");
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  // STEP 2
  const handleOTPCode = (e) => {
    setOtpCode(e);
  };

  const handleOTPCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!otpCode) {
        setMessage("Enter 6 digits code.");
        return;
      }
      setIsLoading(true);
      const res = await putOtpCode(email, otpCode);
      setMessage(res.message);
      setSteps("Step 3");
      setIsLoading(false);
      AuthInputRef.current?.clear();
    } catch (error) {
      AuthInputRef.current?.clear();
      setMessage(error.response.data.message);
    }
  };

  //   STEP 3
  const handleNewPassword = (e, type) => {
    setNewPassword({ ...newPassword, [type]: e.target.value });
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();

    try {
      if (newPassword.password !== newPassword.confirmPassword) {
        setMessage("Passwords don't match.");
        return;
      }

      if (
        passwordValidator.test(newPassword.password) === false ||
        passwordValidator.test(newPassword.confirmPassword) === false
      ) {
        setMessage("Password must have between 12 and 64 characters.");
        return;
      }
      setIsLoading(true);
      const res = await putNewPassword(email, newPassword.password);
      setMessage(res.message);
      toast.success("Your password was updated!");

      setTimeout(() => {
        setIsLoading(false);
        navigate("/login");
      }, 1000);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <section className="forgot-password__container">
      <div>
        <Title title={steps} />
        {steps === "Step 1" && (
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="input_btn__wrapper_email">
              <Input
                inputType={"text"}
                inputValue={email}
                inputLabel={"Confirm email address"}
                isRequired={true}
                handleChange={(e) => handleChange(e)}
                error={message}
              />

              <Button
                btnStyle={"primary"}
                content={"Send"}
                isDisabled={!email.length || isLoading}
                type="submit"
              />
            </div>
          </form>
        )}

        {steps === "Step 2" && (
          <form>
            <div className="input_btn__wrapper_code">
              <div>
                <AuthCode
                  containerClassName={"authcode__container"}
                  inputClassName={"authcode__input text-center"}
                  allowedCharacters="numeric"
                  onChange={(e) => handleOTPCode(e)}
                  ref={AuthInputRef}
                />
                {message && (
                  <p className="forgot-password__message">{message}</p>
                )}
              </div>
              <div className="btn_code__wrapper">
                <Button
                  btnStyle={"primary"}
                  content={"Confirm"}
                  isDisabled={!email.length || isLoading}
                  action={(e) => handleOTPCodeSubmit(e)}
                />
                <Button
                  btnStyle={"secondary"}
                  content={"Re-send email"}
                  action={(e) => {
                    AuthInputRef.current?.clear();
                    handleSubmit(e);
                  }}
                />
              </div>
            </div>
          </form>
        )}

        {steps === "Step 3" && (
          <form onSubmit={(e) => submitNewPassword(e)}>
            <div className="input_btn__wrapper_email">
              <div className="input_message__wrapper">
                <Input
                  inputType={"password"}
                  inputLabel={"New password"}
                  isRequired={true}
                  handleChange={(e) => handleNewPassword(e, "password")}
                />

                <Input
                  inputType={"password"}
                  inputLabel={"Confirm new password"}
                  isRequired={true}
                  handleChange={(e) => handleNewPassword(e, "confirmPassword")}
                />
                {message && (
                  <p className="forgot-password__message">{message}</p>
                )}
              </div>
              <Button
                btnStyle={"primary"}
                content={"Reset password"}
                isDisabled={
                  isLoading ||
                  !!(
                    !newPassword.password.length ||
                    !newPassword.confirmPassword.length
                  )
                }
              />
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default ForgotPassword;
