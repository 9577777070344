/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const PaginationContext = createContext();

function PaginationProviderWrapper({ children }) {
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [cityCurrentPage, setCityCurrentPage] = useState(1);
  const [cityPerPage, setCityPerPage] = useState(10);
  const [userPerPage, setUserPerPage] = useState(10);

  return (
    <PaginationContext.Provider
      value={{
        setUserCurrentPage,
        setCityCurrentPage,
        userCurrentPage,
        cityCurrentPage,
        cityPerPage,
        setCityPerPage,
        userPerPage,
        setUserPerPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}

export { PaginationProviderWrapper, PaginationContext };
