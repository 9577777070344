import { useEffect, useState } from "react";
import { getOneUser } from "views/UserDetails/queries";
import Button from "components/general/Button";
import Input from "components/general/Input";
import Title from "components/general/Title";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editUser } from "./queries";
import profileImg from "../../assets/images/profile-image-placeholder.png";
import "./edit-user.scss";

const EditUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({
    email: "",
    phone: "",
  });
  const [profilePic, setProfilePic] = useState({ image: "", url: "" });
  const [errorObj, setErrorObj] = useState({
    usernameError: "",
    firstnameError: "",
    lastnameError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneUser(userId);
        setUser({
          userName: res.data?.username,
          firstName: res.data?.firstName,
          lastName: res.data?.lastName,
          bio: res.data?.bio || undefined,
          friendsOfFriends: res.data?.friendsOfFriends,
          offTheGrid: res.data?.offTheGrid,
        });

        setProfilePic({
          image: res.data?.profilePicture,
          url: res.data?.userBaseUrl,
        });

        setDisabledInputs({
          email: res.data?.email,
          phone: `${res.data?.countryCode} ${res.data?.phone}`,
        });
      } catch (error) {
        toast.error("There was a problem, please try again!");
      }
    })();
  }, [userId]);

  const handleInputChange = (e, type) => {
    setUser({ ...user, [type]: e.target.value });
  };

  const handleEditUser = async (e, userId, userData) => {
    e.preventDefault();

    if (
      !userData.userName.length &&
      !userData.firstName.length &&
      !userData.lastName.length
    ) {
      setErrorObj({
        firstnameError: "First name cannot be empty",
        lastnameError: "Last name cannot be empty",
        usernameError: "Username cannot be empty",
      });
      return;
    }

    if (!userData.firstName.length && !userData.lastName.length) {
      setErrorObj({
        firstnameError: "First name cannot be empty",
        lastnameError: "Last name cannot be empty",
        usernameError: "",
      });
      return;
    }

    if (!userData.firstName.length) {
      setErrorObj({
        firstnameError: "First name cannot be empty",
        lastnameError: "",
        usernameError: "",
      });
      return;
    }

    if (!userData.lastName.length) {
      setErrorObj({
        firstnameError: "",
        lastnameError: "Last name cannot be empty",
        usernameError: "",
      });
      return;
    }

    if (!userData.userName.length) {
      setErrorObj({
        firstnameError: "",
        lastnameError: "",
        usernameError: "Username cannot be empty",
      });
      return;
    }

    if (!userData.bio?.length || userData.bio === null) {
      userData.bio = undefined;
    }

    setIsLoading(true);
    try {
      const res = await editUser(userId, userData);
      toast.success(`${res.data?.userName} updated successfully!`);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/users");
      }, 500);
    } catch (error) {
      setIsLoading(false);
      toast.warning(`${error.response.data.message}`);
    }
  };

  return (
    <section className="edit__container">
      <div className="edit__wrapper">
        <div className="edit_header__wrapper">
          <Title title="Edit User" />

          <img
            className="edit_header__image"
            src={
              profilePic?.image
                ? `${profilePic?.url}/${profilePic?.image}`
                : profileImg
            }
            alt={`${user?.username}-picture`}
          />
        </div>

        <hr className="divider" />

        <form
          className="edit__form"
          onSubmit={(e) => handleEditUser(e, userId, user)}
        >
          <Input
            inputType="text"
            inputLabel="First Name"
            inputValue={user?.firstName}
            handleChange={(e) => handleInputChange(e, "firstName")}
            error={errorObj.firstnameError}
          />

          <Input
            inputType="text"
            inputLabel="Last Name"
            inputValue={user?.lastName}
            handleChange={(e) => handleInputChange(e, "lastName")}
            error={errorObj.lastnameError}
          />

          <Input
            inputType="text"
            inputLabel="Username"
            inputValue={user?.userName}
            handleChange={(e) => handleInputChange(e, "userName")}
            error={errorObj.usernameError}
          />

          <Input
            inputType="textarea"
            inputLabel="Bio"
            inputValue={user?.bio}
            handleChange={(e) => handleInputChange(e, "bio")}
            maxLenght="70"
          />

          <Input
            inputType="text"
            inputLabel="Email"
            isDisabled={true}
            inputValue={disabledInputs?.email}
          />

          <Input
            inputType="text"
            inputLabel="Phone"
            isDisabled={true}
            inputValue={disabledInputs?.phone}
          />

          <div className="switch__wrapper">
            <Input
              inputType={"switch"}
              inputLabel="Friends of friends"
              isChecked={user?.friendsOfFriends}
              handleChange={() =>
                setUser({ ...user, friendsOfFriends: !user.friendsOfFriends })
              }
            />

            <Input
              inputType={"switch"}
              inputLabel="Off the grid"
              isChecked={user?.offTheGrid}
              handleChange={() =>
                setUser({ ...user, offTheGrid: !user.offTheGrid })
              }
            />
          </div>

          <div className="edit_btn__wrapper">
            <Button
              content={"Cancel"}
              btnStyle="secondary"
              action={() => navigate("/users")}
            />
            <Button content={"Save"} btnStyle="save" isDisabled={isLoading} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditUser;
