import { useContext, useEffect, useState } from "react";
import { Table } from "components/index";
import { PaginationContext } from "helpers/pagination.context";
import { selectOptions } from "helpers/selectOptions";
import { deleteOneUser, getUsers } from "./queries";
import { tableActions, tableHeaders } from "./constants";
import "./users.scss";

const Users = () => {
  const { setUserCurrentPage, userCurrentPage, userPerPage, setUserPerPage } =
    useContext(PaginationContext);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [query, setQuery] = useState("");
  const [pageCount, setPageCount] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getUsers(userCurrentPage, userPerPage, query);
      setTotalCount(res?.data?.count);
      setPageCount(Math.ceil(res?.data?.count / userPerPage));

      const usersFromDb = res?.data?.list.map((user) => {
        if (user.offTheGrid) {
          return { ...user, cityName: "Off the Grid" };
        } else if (user.weynStatus === "out_of_town") {
          return { ...user, cityName: "Out of Town" };
        } else {
          return user;
        }
      });

      console.log(usersFromDb);
      setUsers(usersFromDb);
    })();
  }, [userCurrentPage, query, userPerPage]);

  const handleSearch = (e) => {
    setTimeout(() => {
      setUserCurrentPage(1);
      setQuery(e.target.value);
    }, 1000);
  };

  return (
    <section className="tablesection__container">
      <Table
        selectOptions={selectOptions}
        setPerPage={setUserPerPage}
        totalCount={totalCount}
        perPage={userPerPage}
        searchInTable={handleSearch}
        setQuery={setQuery}
        pageCount={pageCount}
        currentPage={userCurrentPage}
        setCurrentPage={setUserCurrentPage}
        dataList={users}
        headersList={tableHeaders}
        tableTitle="Users"
        actions={tableActions}
        deleteContent={deleteOneUser}
      />
    </section>
  );
};

export default Users;
