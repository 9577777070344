import CircularProgress from "@mui/material/CircularProgress";
import "./loader.scss";

const Loader = () => {
  return (
    <div className="loader__wrapper">
      <CircularProgress size="100px" sx={{ color: "blueviolet" }} />
    </div>
  );
};

export default Loader;
