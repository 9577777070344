/* eslint-disable react/prop-types */
import "./select.scss";

const Select = ({ options, onChange, className, inputLabel, inputValue }) => {
  return (
    <div className="select__wrapper">
      <label htmlFor={inputLabel}>{inputLabel}</label>
      <select
        defaultValue={inputValue}
        className={className}
        onChange={onChange}
      >
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
