import { useContext, useState } from "react";
import Button from "components/general/Button";
import Input from "components/general/Input";
import { useNavigate } from "react-router";
import { Link, Navigate } from "react-router-dom";
import { UserContext } from "helpers/user.context";
import { emailValidator, passwordValidator } from "helpers/validation";
import { loginUser } from "./queries";
import weynLogo from "../../assets/images/weyn_logo 1weyn_logo.png";
import "./login.scss";

const Login = () => {
  const { storeToken, authenticateUser, storeAdmin, isLoggedIn } =
    useContext(UserContext);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    loginIdentifier: "",
    password: "",
  });
  const [errorObj, setErrorObj] = useState({
    emailError: null,
    passwordError: null,
  });

  const handleChange = (e, type) => {
    setLoginData({
      ...loginData,
      [type]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { loginIdentifier, password } = loginData;

    if (!loginIdentifier.length || !password.length) {
      setErrorObj({
        emailError: "Please fill both fields",
        passwordError: "Please fill both fields",
      });
      return;
    }

    if (
      emailValidator.test(loginIdentifier) === false &&
      passwordValidator.test(password) === false
    ) {
      setErrorObj({
        emailError: "Please enter a valid email address",
        passwordError: "Password must have between 12 and 64 characters.",
      });
      return;
    }

    if (emailValidator.test(loginIdentifier) === false) {
      setErrorObj({
        emailError: "Please enter a valid email address",
        passwordError: null,
      });
      return;
    }

    if (passwordValidator.test(password) === false) {
      setErrorObj({
        emailError: null,
        passwordError: "Password must have between 12 and 64 characters.",
      });
      return;
    }

    setIsLoading(true);
    try {
      const res = await loginUser(loginData);
      storeToken(res?.data?.token);
      storeAdmin(res?.data?.id);
      authenticateUser();
      setIsLoading(false);
      navigate("/users");
    } catch (error) {
      setErrorObj({
        emailError: "",
        passwordError: `${error.response.data.message}, try again.`,
      });
    }
  };

  if (isLoggedIn) {
    return <Navigate to={"/users"} />;
  }

  return (
    <section className="login_section__container flex-center">
      <div className="login__container">
        <div>
          <img src={weynLogo} alt="weyn-logo" className="logo__large" />
        </div>
        <form className="login_form" onSubmit={(e) => handleSubmit(e)}>
          <Input
            inputType="text"
            inputLabel="Email"
            isRequired={true}
            handleChange={(e) => handleChange(e, "loginIdentifier")}
            error={errorObj.emailError}
          />

          <Input
            inputType="password"
            inputLabel="Password"
            isRequired={true}
            handleChange={(e) => handleChange(e, "password")}
            error={errorObj.passwordError}
          />

          <div>
            <Button
              content="Login"
              btnStyle="primary"
              isDisabled={
                isLoading ||
                !!(
                  !loginData.loginIdentifier.length ||
                  !loginData.password.length
                )
              }
              type="sumbit"
            />
            <Link to={"/forgot-password"} className="forgot_password__link">
              <p className="text-center forgot_password">Forgot password?</p>
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
