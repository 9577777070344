/* eslint-disable @typescript-eslint/no-var-requires */
import { useRef, useState } from "react";
import { putImage } from "views/EditCity/queries";
import { Button, Input, Title } from "components/index";
import { IoCloudUploadSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { textOnlyValidator } from "helpers/validation";
import { addNewCity } from "./queries";
import placeholderImg from "../../assets/images/placeholder-city-image.png";
import "./add-city.scss";

const AddCity = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newCity, setNewCity] = useState({
    name: "",
    status: true,
    country: "",
    longitude: 0,
    latitude: 0,
  });
  const fileInput = useRef(null);
  const [imageUpload, setImageUpload] = useState({ image: "" });
  const [errorObj, setErrorObj] = useState({
    cityError: "",
    countryError: "",
    latError: "",
    longError: "",
  });

  const handleInputChange = (e, type) => {
    if (type === "name") {
      const res = e.target.value.replace(textOnlyValidator, "");
      return setNewCity({ ...newCity, [type]: res });
    }

    if (type === "country") {
      const res = e.target.value.replace(textOnlyValidator, "");
      return setNewCity({ ...newCity, [type]: res });
    }

    setNewCity({
      ...newCity,
      [type]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const fileUploaded = e.target.files[0];

    if (fileUploaded) {
      const filereader = new FileReader();
      filereader.readAsDataURL(fileUploaded);
      filereader.onload = (e) => {
        const base64 = e.target.result;
        setImageUpload({
          ...imageUpload,
          image: base64,
        });
      };
    }
  };

  const handleSubmit = async (e, cityData) => {
    e.preventDefault();

    if (
      !cityData.country.length &&
      !cityData.name.length &&
      !cityData.latitude.length &&
      !cityData.longitude.length
    ) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "Please fill in this field",
        longError: "Please fill in this field",
        latError: "Please fill in this field",
      });
      return;
    }

    if (!cityData.country.length && !cityData.name.length) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "Please fill in this field",
        longError: "",
        latError: "",
      });
      return;
    }

    if (!cityData.name.length) {
      setErrorObj({
        cityError: "Please fill in this field",
        countryError: "",
        longError: "",
        latError: "",
      });
      return;
    }

    if (!cityData.country.length) {
      setErrorObj({
        cityError: "",
        countryError: "Please fill in this field",
        longError: "",
        latError: "",
      });
      return;
    }

    if (!cityData.latitude.length && !cityData.longitude.length) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Please fill in this field",
        latError: "Please fill in this field",
      });
      return;
    }

    if (!cityData.latitude.length) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "",
        latError: "Please fill in this field",
      });
      return;
    }

    if (!cityData.longitude.length) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Please fill in this field",
        latError: "",
      });
      return;
    }

    if (cityData.longitude > 180 && cityData.latitude > 90) {
      setErrorObj({
        cityError: "",
        countryError: "",
        longError: "Longitude must be up to 180",
        latError: "Latitude must be up to 90",
      });
      return;
    }

    if (cityData.longitude > 180) {
      setErrorObj({
        latError: "",
        cityError: "",
        countryError: "",
        longError: "Longitude must be up to 180",
      });
      return;
    }

    if (cityData.latitude > 90) {
      setErrorObj({
        longError: "",
        cityError: "",
        countryError: "",
        latError: "Latitude must be up to 90",
      });
      return;
    }

    setIsLoading(true);
    try {
      const res = await addNewCity(cityData);
      const id = res.data.id;

      if (imageUpload.image.length) {
        await putImage({ cityId: id, image: imageUpload.image });
      }

      toast.success("City created successfully");
      setIsLoading(false);
      navigate("/cities");
    } catch (error) {
      toast.warning(`${error.response.data.message}`);
    }
  };

  return (
    <section className="add_city__container">
      <div className="add_city__wrapper">
        <div className="add_city_header">
          <Title title="Add City" />

          <div className="icon_img__wrapper">
            <IoCloudUploadSharp
              className="upload_icon"
              onClick={() => fileInput.current.click()}
            />

            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/svg"
              onChange={handleFileChange}
              ref={fileInput}
            />

            {imageUpload?.image ? (
              <img
                className="edit__image"
                src={imageUpload.image}
                alt={"new-city-picture"}
                onClick={() => fileInput.current.click()}
              />
            ) : (
              <img
                className="edit__image"
                src={placeholderImg}
                alt={"new-city-picture"}
                onClick={() => fileInput.current.click()}
              />
            )}
          </div>
        </div>

        <hr className="divider" />
        <form
          className="add_city__form"
          onSubmit={(e) => handleSubmit(e, newCity)}
        >
          <Input
            inputType="text"
            inputLabel="City Name"
            handleChange={(e) => handleInputChange(e, "name")}
            inputValue={newCity.name}
            error={errorObj.cityError}
          />

          <Input
            inputType="text"
            inputLabel="Country"
            handleChange={(e) => handleInputChange(e, "country")}
            inputValue={newCity.country}
            error={errorObj.countryError}
          />

          <Input
            inputType="number"
            inputStep="any"
            inputLabel="Latitude"
            placeholderText={"Maximum 90"}
            handleChange={(e) => handleInputChange(e, "latitude")}
            error={errorObj.latError}
          />

          <Input
            inputType="number"
            inputStep="any"
            inputLabel="Longitude"
            placeholderText={"Maximum 180"}
            handleChange={(e) => handleInputChange(e, "longitude")}
            error={errorObj.longError}
          />

          <div className="edit_btn__wrapper">
            <Button
              content={"Cancel"}
              btnStyle="secondary"
              action={() => navigate("/cities")}
            />

            <Button btnStyle={"save"} content="Create" isDisabled={isLoading} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddCity;
