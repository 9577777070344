/* eslint-disable indent */
import {
  AddCity,
  Cities,
  CityDetails,
  Content,
  ContentDetails,
  EditCity,
  EditContent,
  EditUser,
  Faqs,
  ForgotPassword,
  Login,
  Navbar,
  NotFound,
  Privacy,
  Profile,
  TermsAndConditions,
  UserDetails,
  Users,
} from "components/index";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { IsPrivate } from "helpers/isPrivate";

const MainRouter = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/terms" &&
      location.pathname !== "/privacy" &&
      location.pathname !== "/faq" ? (
        <>
          <Navbar />
        </>
      ) : null}

      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/profile"
          element={
            <IsPrivate>
              <Profile />
            </IsPrivate>
          }
        />

        <Route
          path="/users"
          element={
            <IsPrivate>
              <Users />
            </IsPrivate>
          }
        />

        <Route
          path="/users/:userId"
          element={
            <IsPrivate>
              <UserDetails />
            </IsPrivate>
          }
        />

        <Route
          path="/users/edit/:userId"
          element={
            <IsPrivate>
              <EditUser />
            </IsPrivate>
          }
        />

        <Route
          path="/content"
          element={
            <IsPrivate>
              <Content />
            </IsPrivate>
          }
        />

        <Route
          path="/content/:contentId"
          element={
            <IsPrivate>
              <ContentDetails />
            </IsPrivate>
          }
        />

        <Route
          path="/content/edit/:contentId"
          element={
            <IsPrivate>
              <EditContent />
            </IsPrivate>
          }
        />

        <Route
          path="/cities"
          element={
            <IsPrivate>
              <Cities />
            </IsPrivate>
          }
        />

        <Route
          path="/cities/:cityId"
          element={
            <IsPrivate>
              <CityDetails />
            </IsPrivate>
          }
        />

        <Route
          path="/add-new-city"
          element={
            <IsPrivate>
              <AddCity />
            </IsPrivate>
          }
        />

        <Route
          path="/cities/edit/:cityId"
          element={
            <IsPrivate>
              <EditCity />
            </IsPrivate>
          }
        />
        {/* Route not found */}
        <Route path="/*" element={<NotFound />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/faq" element={<Faqs />} />
      </Routes>
    </>
  );
};

export default MainRouter;
