import { useEffect, useState } from "react";
import { getOneUser } from "views/UserDetails/queries";
import { Button, Input, Title } from "components/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetAdminPassword } from "./queries";
import "./profile.scss";

const Profile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState({});
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
    currentPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneUser(localStorage.getItem("admin"));
        setAdmin(res?.data);
      } catch (error) {
        toast.error("There was an error, try again.");
      }
    })();
  }, []);

  const handleChange = (e, type) => {
    setPasswordData({ ...passwordData, [type]: e.target.value });
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await resetAdminPassword(data);
      toast.success("Password was updated!");
      setTimeout(() => {
        setIsLoading(false);
        navigate("/users");
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <section className="profile__container">
      <div className="profile__wrapper">
        <div className="title__wrapper">
          <Title title="Admin Account" />
        </div>
        <div className="details__wrapper">
          <Input
            inputType="text"
            inputLabel="Name"
            isDisabled={true}
            inputValue={`${admin?.firstName} ${admin?.lastName}`}
          />

          <Input
            inputType="email"
            inputLabel="Email"
            isDisabled={true}
            inputValue={admin?.email}
          />
        </div>
        <hr className="profile__divider" />
        <div className="subtitle__wrapper">
          <h2>Update Password</h2>
          <em>Your password must have between 12 and 64 characters.</em>
        </div>

        <form
          className="profile_form__wrapper"
          onSubmit={(e) => handleSubmit(e, passwordData)}
        >
          <div className="details__input_wrapper">
            <Input
              inputType="password"
              inputLabel="Current password"
              handleChange={(e) => handleChange(e, "currentPassword")}
              inputValue={passwordData.currentPassword}
            />
            <Input
              inputType="password"
              inputLabel="New password"
              handleChange={(e) => handleChange(e, "password")}
              inputValue={passwordData.password}
            />
            <Input
              inputType="password"
              inputLabel="Confirm new password"
              handleChange={(e) => handleChange(e, "confirmPassword")}
              inputValue={passwordData.confirmPassword}
              error={errorMessage}
            />
          </div>
          <div className="profile_btn__wrapper">
            <Button
              content="Cancel"
              btnStyle="secondary"
              action={() => navigate("/users")}
            />
            <Button
              content="Reset"
              btnStyle="primary"
              isDisabled={
                isLoading ||
                !passwordData.currentPassword.length ||
                !passwordData.password.length ||
                !passwordData.confirmPassword.length
              }
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Profile;
