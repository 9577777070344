/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const UserContext = createContext();

function UserProviderWrapper({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const storeToken = (token) => {
    localStorage.setItem("x-auth-token", token);
  };

  const storeAdmin = (id) => {
    localStorage.setItem("admin", id);
  };

  const removeToken = () => {
    localStorage.removeItem("x-auth-token");
    localStorage.removeItem("admin");
  };

  const authenticateUser = () => {
    const storedToken = localStorage.getItem("x-auth-token");
    const storedAdmin = localStorage.getItem("admin");

    if (storedToken && storedAdmin) {
      (async () => {
        try {
          setIsLoggedIn(true);
          setIsLoading(false);
        } catch (e) {
          setIsLoggedIn(false);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  };

  const logoutUser = () => {
    removeToken();
    authenticateUser();
    navigate("/login");
    toast.info("Goodbye for now!");
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        storeToken,
        storeAdmin,
        authenticateUser,
        logoutUser,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserProviderWrapper, UserContext };
