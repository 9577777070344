import { useContext, useEffect, useState } from "react";
import { Table } from "components/index";
import { PaginationContext } from "helpers/pagination.context";
import { selectOptions } from "helpers/selectOptions";
import { deleteCity, getCities } from "./queries";
import { citiesActions, citiesHeaders } from "./constants";

const Cities = () => {
  const { setCityCurrentPage, cityCurrentPage, cityPerPage, setCityPerPage } =
    useContext(PaginationContext);
  const [cities, setCities] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [query, setQuery] = useState("");
  const [pageCount, setPageCount] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getCities(cityCurrentPage, cityPerPage, query);
      setTotalCount(res?.data?.count);
      setPageCount(Math.ceil(res?.data?.count / cityPerPage));
      setCities(res?.data?.list);
    })();
  }, [cityCurrentPage, query, cityPerPage]);

  const handleSearch = (e) => {
    setTimeout(() => {
      setCityCurrentPage(1);
      setQuery(e.target.value);
    }, 1000);
  };

  return (
    <section className="tablesection__container">
      <Table
        selectOptions={selectOptions}
        setPerPage={setCityPerPage}
        totalCount={totalCount}
        perPage={cityPerPage}
        searchInTable={handleSearch}
        setQuery={setQuery}
        searchPlaceholder="Search by city or country name..."
        pageCount={pageCount}
        currentPage={cityCurrentPage}
        setCurrentPage={setCityCurrentPage}
        dataList={cities}
        headersList={citiesHeaders}
        tableTitle="Cities"
        actions={citiesActions}
        deleteContent={deleteCity}
      />
    </section>
  );
};

export default Cities;
