/* eslint-disable react/prop-types */
import Button from "components/general/Button";
import { Skeleton } from "@mui/material";
import dateFormat from "dateformat";
import profileImg from "../../../assets/images/profile-image-placeholder.png";
import "./user-card.scss";

const UserCard = ({ userData, goBack, editUser, isLoading }) => {
  return (
    <article className="card__container">
      <div className="card__wrapper">
        <header className="text-center">
          {isLoading ? (
            <>
              <Skeleton
                variant="circular"
                height={200}
                width={200}
                animation="wave"
                sx={{ animationDuration: "0.2s" }}
              />
              <Skeleton
                variant="text"
                height={60}
                animation="wave"
                sx={{ fontSize: "1.8rem", animationDuration: "0.2s" }}
              />
            </>
          ) : (
            <>
              <img
                className="profile_image"
                src={
                  userData?.profilePicture
                    ? `${userData?.userBaseUrl}/${userData?.profilePicture}`
                    : profileImg
                }
                alt={`${userData?.username}-picture`}
              />
              <h2>{userData?.username}</h2>
            </>
          )}
        </header>

        <ul className="text-left card_info__wrapper">
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Name:</span> {userData?.firstName} {userData?.lastName}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Email:</span> {userData?.email}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Phone:</span>{" "}
                {`${userData?.countryCode} ${userData?.phone}`}
              </p>
            )}
          </li>
          {userData?.cityName && (
            <li>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={200}
                  animation="wave"
                  sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
                />
              ) : (
                <p>
                  <span>City:</span> {userData?.cityName}
                </p>
              )}
            </li>
          )}
          {userData?.bio && (
            <li>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={200}
                  animation="wave"
                  sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
                />
              ) : (
                <p>
                  <span>Bio:</span> {userData?.bio}
                </p>
              )}
            </li>
          )}
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Off the grid:</span> {userData?.offTheGrid ? "Yes" : "No"}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Friends of friends:</span>{" "}
                {userData?.friendsOfFriends ? "Yes" : "No"}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Created:</span>{" "}
                {dateFormat(userData?.createdAt, "mediumDate")}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{
                  fontSize: "1.4rem",
                  animationDuration: "0.2s",
                }}
              />
            ) : (
              <p>
                <span>Updated:</span>{" "}
                {dateFormat(userData?.updatedAt, "mediumDate")}
              </p>
            )}
          </li>
        </ul>
        {!isLoading && (
          <div className="btn__wrapper">
            <Button
              btnStyle={"primary"}
              content="Edit user"
              action={editUser}
            />
            <Button btnStyle={"secondary"} content="Go back" action={goBack} />
          </div>
        )}
      </div>
    </article>
  );
};

export default UserCard;
