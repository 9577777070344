export const citiesActions = {
  addUrl: "/add-new-city",
  viewUrl: "/cities/",
  editUrl: "/cities/edit/",
};

export const citiesHeaders = [
  {
    Header: "City Name",
    accessor: "name",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Users Located",
    accessor: "users",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
