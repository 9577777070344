import axios from "axios";

export const editCity = async (cityId, cityData) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/city/${cityId}`,
    cityData,
    {
      headers: {
        "content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("x-auth-token")}`,
      },
    }
  );

  return res.data;
};

export const putImage = async (imageData) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/city/image`,
    imageData,
    {
      headers: {
        "content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("x-auth-token")}`,
      },
    }
  );

  return res.data;
};
