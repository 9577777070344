/* eslint-disable react/prop-types */
import { Switch } from "@mui/material";
import "./input.scss";

const Input = ({
  inputType,
  inputLabel,
  isRequired,
  isDisabled,
  handleChange,
  inputStep,
  error,
  placeholderText,
  inputValue,
  inputWidth,
  maxLenght,
  maxNumber,
  minNumber,
  isChecked,
  tooltipID,
  tooltipText,
}) => {
  return (
    <>
      {inputType !== "textarea" && inputType !== "switch" && (
        <div className="input__wrapper">
          <label htmlFor={inputType}>{inputLabel}</label>
          <input
            style={{ width: `${inputWidth}` }}
            value={inputValue}
            placeholder={placeholderText}
            required={isRequired}
            disabled={isDisabled}
            type={inputType}
            max={maxNumber}
            min={minNumber}
            onChange={handleChange}
            step={inputStep}
            data-tooltip-id={tooltipID}
            data-tooltip-content={tooltipText}
          />
          <p className="error__message">{error}</p>
        </div>
      )}

      {inputType === "textarea" && (
        <div className="input__wrapper">
          <label htmlFor={inputType}>{inputLabel}</label>
          <textarea
            style={{ width: `${inputWidth}` }}
            value={inputValue}
            placeholder={placeholderText}
            required={isRequired}
            disabled={isDisabled}
            type={inputType}
            onChange={handleChange}
            maxLength={maxLenght}
            data-tooltip-id={tooltipID}
            data-tooltip-content={tooltipText}
          />
          <p className="error__message">{error}</p>
        </div>
      )}

      {inputType === "switch" && (
        <div className="input__wrapper">
          <label htmlFor={inputType}>{inputLabel}</label>
          <Switch checked={isChecked} onChange={handleChange} />
        </div>
      )}
    </>
  );
};

export default Input;
