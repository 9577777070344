import { useEffect, useState } from "react";
import { Section, UserCard } from "components/index";
import { useNavigate, useParams } from "react-router-dom";
import { getOneUser } from "./queries";

const UserDetails = () => {
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await getOneUser(userId);
      setUserInfo(res.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    })();
  }, [userId]);

  return (
    <Section>
      <UserCard
        userData={userInfo}
        goBack={() => navigate(-1)}
        editUser={() => navigate(`/users/edit/${userId}`)}
        isLoading={isLoading}
      />
    </Section>
  );
};

export default UserDetails;
