/* eslint-disable react/prop-types */
import Button from "components/general/Button";
import Title from "components/general/Title";
import { Skeleton } from "@mui/material";
import dateFormat from "dateformat";
import DOMPurify from "dompurify";
import "./content-card.scss";

const ContentCard = ({ contentData, goBack, editContent, isLoading }) => {
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <article>
      <div className="content_card__wrapper">
        <Title title={contentData?.title} />
        <hr className="divider" />
        <div className="content_card_info__wrapper">
          {isLoading ? (
            <Skeleton
              variant="text"
              width={200}
              animation="wave"
              sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
            />
          ) : (
            <p>
              <span>Description:</span>
            </p>
          )}
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width={600}
              height={300}
              animation="wave"
              sx={{ animationDuration: "0.2s" }}
            />
          ) : (
            <div
              className="static__container description__wrapper"
              dangerouslySetInnerHTML={createMarkup(contentData?.description)}
            ></div>
          )}

          {isLoading ? (
            <Skeleton
              variant="text"
              width={200}
              animation="wave"
              sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
            />
          ) : (
            <p>
              <span>Created:</span>{" "}
              {dateFormat(contentData?.createdAt, "mediumDate")}
            </p>
          )}
          {isLoading ? (
            <Skeleton
              variant="text"
              width={200}
              animation="wave"
              sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
            />
          ) : (
            <p>
              <span>Last updated:</span>{" "}
              {dateFormat(contentData?.updatedAt, "mediumDate")}
            </p>
          )}
        </div>

        {!isLoading && (
          <div className="btn__wrapper">
            <Button
              btnStyle={"primary"}
              content="Edit content"
              action={editContent}
            />
            <Button btnStyle={"secondary"} content="Go back" action={goBack} />
          </div>
        )}
      </div>
    </article>
  );
};

export default ContentCard;
