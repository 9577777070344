import axios from "axios";

export const getFaqs = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/faq`);
  return res.data;
};

export const getTerms = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/terms`);
  return res.data;
};

export const getPrivacy = async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/privacy`);
  return res.data;
};
