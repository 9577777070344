/* eslint-disable react/prop-types */
import Button from "components/general/Button";
import Modal from "react-modal";
import "./popup.scss";

const PopUp = ({ openPopUp, setOpenPopUp, deleteAction, isLoading }) => {
  const closeModal = () => {
    setOpenPopUp(false);
  };

  return (
    <Modal isOpen={openPopUp} className="modal">
      <div className="modal__container">
        <h2 className="text-center">Are you sure you want to delete?</h2>
        <div className="actions__wrapper">
          <Button btnStyle={"secondary"} content="No" action={closeModal} />
          <Button
            btnStyle={"danger"}
            content="Yes"
            action={deleteAction}
            isDisabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PopUp;
