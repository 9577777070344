import MainRouter from "router/MainRouter";

const Webapp = () => {
  return (
    <>
      <MainRouter />
    </>
  );
};

export default Webapp;
