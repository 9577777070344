/* eslint-disable react/prop-types */
import "./section.scss";

const Section = ({ children }) => {
  return (
    <section className="section__container flex-center">{children}</section>
  );
};

export default Section;
