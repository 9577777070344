import { useEffect, useState } from "react";
import { Table } from "components/index";
import dateFormat from "dateformat";
import { getContent } from "./queries";
import { contentActions } from "./constants";
import { contentHeaders } from "./constants";

const Content = () => {
  const [content, setContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const perPage = 10;

  useEffect(() => {
    (async () => {
      const res = await getContent(currentPage, perPage);
      res.data?.list.map((content) => {
        if (content.updatedAt) {
          return (content.updatedAt = dateFormat(
            content.updatedAt,
            "mediumDate"
          ));
        }
      });
      setPageCount(Math.ceil(res?.data?.count / perPage));
      setContent(res.data.list);
    })();
  }, [currentPage]);

  return (
    <section className="tablesection__container">
      <Table
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        dataList={content}
        headersList={contentHeaders}
        tableTitle="Content"
        actions={contentActions}
      />
    </section>
  );
};

export default Content;
