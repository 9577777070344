/* eslint-disable react/prop-types */
import Button from "components/general/Button";
import { Skeleton } from "@mui/material";
import placeholderImg from "../../../assets/images/placeholder-city-image.png";

const CityCard = ({ cityData, goBack, editCity, isLoading }) => {
  return (
    <article className="card__container">
      <div className="card__wrapper">
        <header className="text-center">
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                height={200}
                width={200}
                animation="wave"
                sx={{ animationDuration: "0.2s" }}
              />
              <Skeleton
                variant="text"
                height={60}
                animation="wave"
                sx={{ fontSize: "1.8rem", animationDuration: "0.2s" }}
              />
            </>
          ) : (
            <>
              <img
                className="profile_image"
                src={
                  cityData?.image
                    ? `${cityData?.cityBaseUrl}/${cityData?.image}`
                    : placeholderImg
                }
                alt={`${cityData?.name}-picture`}
              />
              <h2>{cityData?.name}</h2>
            </>
          )}
        </header>

        <ul className="text-left card_info__wrapper">
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Country:</span> {cityData?.country}
              </p>
            )}
          </li>

          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Latitude:</span> {cityData?.latitude}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Longitude:</span> {cityData?.longitude}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Number of users:</span> {cityData?.users}
              </p>
            )}
          </li>
          <li>
            {isLoading ? (
              <Skeleton
                variant="text"
                width={200}
                animation="wave"
                sx={{ fontSize: "1.4rem", animationDuration: "0.2s" }}
              />
            ) : (
              <p>
                <span>Number of trips:</span> {cityData?.trips}
              </p>
            )}
          </li>
        </ul>
        {!isLoading && (
          <div className="btn__wrapper">
            <Button
              btnStyle={"primary"}
              content="Edit city"
              action={editCity}
            />
            <Button btnStyle={"secondary"} content="Go back" action={goBack} />
          </div>
        )}
      </div>
    </article>
  );
};

export default CityCard;
