/* eslint-disable react/prop-types */
import { useContext } from "react";
import { Loader } from "components/index";
import { Navigate } from "react-router-dom";
import { UserContext } from "./user.context";

export const IsPrivate = ({ children }) => {
  const { isLoggedIn, isLoading } = useContext(UserContext);

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};
