export const tableActions = {
  viewUrl: "/users/",
  editUrl: "/users/edit/",
};

export const tableHeaders = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "City Name",
    accessor: "cityName",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
