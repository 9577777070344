/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "helpers/user.context";
import { navLinks } from "./constants";
import weynLogo from "../../../assets/images/weyn_logo 1weyn_logo.png";
import "./navbar.scss";

const Navbar = () => {
  const { isLoggedIn, logoutUser } = useContext(UserContext);

  return (
    <nav className="nav__container">
      <div className="nav__wrapper">
        <div className="logo">
          <img src={weynLogo} alt="weyn-logo" />
        </div>

        <ul className="nav-list">
          {navLinks.map((link, index) => {
            if (isLoggedIn) {
              return (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link_active" : "link"
                    }
                    to={`${link.href}`}
                    onClick={link.name === "Login" ? logoutUser : ""}
                  >
                    {link.name === "Login" ? "Logout" : link.name}
                  </NavLink>
                </li>
              );
            }

            if (!isLoggedIn && link.name === "Login") {
              return (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link_active" : "link"
                    }
                    to={`${link.href}`}
                  >
                    {link.name}
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
