import axios from "axios";

export const resetAdminPassword = async (password) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/password`,
    password,
    {
      headers: {
        "content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("x-auth-token")}`,
      },
    }
  );

  return res.data;
};
