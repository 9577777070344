/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import Input from "../Input";

const TableFilter = ({ searchFunction, searchPlaceholder }) => {
  return (
    <Input
      inputType="text"
      placeholderText={searchPlaceholder || "Search..."}
      handleChange={(e) => searchFunction(e)}
    />
  );
};

export default TableFilter;
