import axios from "axios";

export const forgotPassword = async (email) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/forgot-password`,
    email
  );

  return res.data;
};

export const putOtpCode = async (email, code) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/forgot-password/verify`,
    { email, code }
  );

  return res.data;
};

export const putNewPassword = async (email, password) => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/reset-password`,
    { email, password }
  );

  return res.data;
};
