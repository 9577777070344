export const contentHeaders = [
  {
    Header: "Content",
    accessor: "title",
  },
  {
    Header: "Last updated",
    accessor: "updatedAt",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export const contentActions = {
  viewUrl: "/content/",
  editUrl: "/content/edit/",
};
