/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { getOneContent } from "views/ContentDetails/queries";
import { Section, TextEditor } from "components/index";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editContent } from "./queries";
import "./edit-content.scss";

const EditContent = () => {
  const navigate = useNavigate();
  const { contentId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const res = await getOneContent(contentId);
        if (res.data?.description === null) {
          setContent({
            ...res.data,
            description: `<h1>${res.data?.title}</h1><br/><p>There is no information to show.</p>`,
          });
        } else {
          setContent(res.data);
        }
      } catch (error) {
        toast.error("There was a problem, try again!");
      }
    })();
  }, [contentId]);

  const handleSubmit = async (e, contentData) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await editContent(contentId, contentData);
      toast.success(`${res.data.title} updated successfully!`);
      setIsLoading(false);
      navigate("/content");
    } catch (error) {
      setIsLoading(false);
      toast.warning(`${error.response.data.message}`);
    }
  };

  return (
    <Section>
      <form className="flex-center edit_content__form">
        {content.description && (
          <TextEditor
            contentData={content}
            handleSubmit={handleSubmit}
            goBack={() => navigate("/content")}
            isLoading={isLoading}
          />
        )}
      </form>
    </Section>
  );
};

export default EditContent;
