/* eslint-disable react/prop-types */
import "./title.scss";

const Title = ({ title }) => {
  return (
    <header className="title">
      <h1>{title}</h1>
    </header>
  );
};

export default Title;
